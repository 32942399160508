
<template>
    <div class="column is-12" >
        <div class="column is-12" v-for="(evento,index) in eventos" :key="index" :class="tipoEventoStyle(evento)"  >
            
                <div class="mt-2 column is-1 has-text-centered">
                    <b class="is-size-7-mobile is-size-6 is-paddingless" >{{evento.hour}}</b>
                    <b-icon v-if="evento.tipoAgendamentoCor"
                            :style="tipoAgendamentoStyle(evento)"    
                            size="is-small"                                            
                            icon="square-rounded"></b-icon>
                    <b v-if="!isBloqueio(evento) && isAgendado(evento) && !isConfirmado(evento)" :title="$t('AGENDA.PACIENTENAOCONFIRMADO')" class="has-text-danger">!</b> 
                    <b v-if="!isBloqueio(evento) && isAgendado(evento) && isConfirmado(evento)" :title="$t('AGENDA.PACIENTECONFIRMADO')" class="has-text-success">&radic;</b> 
                    <b v-if="!isBloqueio(evento) && isAgendado(evento) && isEncaixe(evento)" :title="$t('AGENDA.PACIENTEENCAIXE')" class="has-text-black">&crarr;</b> 
                                          
                </div>
                <div class="column ml-2" style="min-height: 40px;">
                    <div class="columns is-flex mediaScreen">
                        <div class="column is-9">
                            <div class="is-size-6-mobile is-size-5" >
                                <span >{{tratarNomePaciente(evento)}}</span>                    
                            </div>
                        </div>
                        <div class="column is-flex is-justify-content-end mr-5 mediaScreen">
                            <div class="is-size-7-mobile is-size-6 mr-2" v-if="evento.executanteNome">
                                <span class="">{{evento.executanteNome}}</span>
                            </div>
                            <div class="is-size-7-mobile is-size-6" v-if="!isBloqueio(evento) && evento.convenioNome">
                                <span >{{evento.convenioNome}}</span>                       
                            </div>
                        </div>                        
                    </div>
                </div>            
                <div class="mt-2" v-show="habilitarBotoes(evento)" style="min-height: 40px;">                    
                        <b-button class="is-pulled-right  is-small is-responsive mr-2" position="is-bottom-left"  v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento)"   :title="$t('AGENDA.BLOQUEARHORARIO')" 
                            icon-left="cancel" type="is-black" @click="bloquearHorario(evento)" ></b-button>                                                          
                        <b-button class="is-pulled-right  is-small is-responsive mr-2" position="is-bottom-left" v-show="isBloqueio(evento)  || isReservado(evento) "  
                            :title="$t('AGENDA.DESBLOQUEARHORARIO')" icon-left="lock-open"  @click="desbloquearHorario(evento.id) "   ></b-button> 

                        <b-checkbox v-if="(exibirCheckBox)" :native-value="true" v-model="evento.selecionado" :disabled="evento.bloquearAgenda"
                            class="is-pulled-right mx-0" v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento) "  @input="verificarAgenda(evento)">
                        </b-checkbox>
                        <b-button v-else class=" is-pulled-right is-small is-responsive" position="is-bottom-left" v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento)" :title="$t('AGENDA.INCLUIRPACIENTE')"  
                            icon-left="account-plus" @click="incluirNovoHorarioDia(evento, evento.dataHora)">
                        </b-button>                                                                                                                  
                </div>
        </div>
    </div>
</template>

<style scoped>
    @media screen and (max-width: 769px){
        .mediaScreen{
            flex-direction: column;
        }
    }
</style>

<script>

import moment from 'moment';

export default {
    props: {
        eventos: Array,        
        itemId: Number,            
        exibirCheckBox: Boolean             
    },
    methods: {

        habilitarBotoes(evento){
            return this.$moment(evento.dataHora).toDate() > new Date();                                
        },        

        tipoAgendamentoStyle(evento) {
            return 'color: #' + evento.tipoAgendamentoCor + '!important'
        },
        tipoEventoStyle(evento) {
            let classes = 'box columns is-mobile is-gapless is-paddingless';
            
            if(this.isBloqueio(evento))
                classes += ' has-text-light has-background-dark';

            if(this.isAgendado(evento))
                classes += ' has-background-info has-text-light';

            if(this.isReservado(evento))
                classes += ' has-background-dark has-text-danger-light';                    
            
            if(evento.descricaoExame)
                classes += ' has-background-success';
            
            return classes;                

        },    
  
        tratarNomePaciente(evento){

            if(evento.pacienteNome){
                return evento.pacienteNome.toLowerCase();
            }else if(evento.observacao == 'Reservado') {
                return this.$t('AGENDA.HORARIORESERVADO');
            }else if(evento.id>0 && !evento.pacienteNome){
                return this.$t('AGENDA.HORARIOBLOQUEADO');
            }else if (evento.descricaoExame) {
                return evento.descricaoExame;  
            }
            
        },

        isReservado(evento){              
            
            return (evento.id && !evento.pacienteNome && evento.observacao=='Reservado');                
        },             
        isBloqueio(evento){              
            
            return (evento.id && (!evento.pacienteNome || !evento.observacao == 'Reservado') && evento.observacao=='Bloqueio');                
        }, 
        isAgendado(evento){              
            return (evento.pacienteNome);                
        },  
        isConfirmado(evento){              
            
            return (evento.pacienteConfirmado);                
        },  
        isEncaixe(evento){              
            
            return (evento.encaixe);                
        },                          
        desbloquearHorario(id){                
            const evento = {id: id};
            this.agendaStatus(evento, 'cancelar');
        },            
                

        verificarAgenda(evento){            
            this.$emit("verificarAgenda", evento);
            
        }, 
        bloquearHorario(evento){            
            const bloqueio = {
                data: evento.dataHora,
                observacao: 'Bloqueio',
                idPaciente: 0,
                idTipoAgendamento: evento.tipoAgendamentoId,
                funcionarioId: null,
                paciente: { id: 0 },
                tipoAgendamento: { id: evento.tipoAgendamentoId},
                equipments: evento.equipments,
                encaixe: evento.encaixe
            }
            
            this.$emit("incluirNovoHorarioDia", bloqueio,evento.dataHora,true);
            
        },          
         incluirNovoHorarioDia(evento,dataHora){   
                        
            
            if(evento.id > 0){

                this.$buefy.dialog.confirm({
                    title: this.$t('AGENDA.ATENCAO'),
                    message: this.$t('AGENDA.CONFIRMAPACIENTE'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {       
                        evento.funcionarioId = null                                                                   
                        this.$emit("incluirNovoHorarioDia", evento,dataHora,false);                        
                    },
                                    
                })

            }else{
                evento.funcionarioId = null ;
                evento.itemId = this.itemId;   
                this.$emit("incluirNovoHorarioDia", evento,dataHora,false);
            }


         },         
        async agendaStatus(evento,acao) {
            const params = [];
            if (evento.id) params.push(`id=${evento.id}`);

            params.push(`acao=${acao}`);
            if (evento.itemId) params.push(`itemId=${evento.itemId}`);

            try {
                const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                const res = await this.$http.get(url);
                this.$emit('loadDataDia');
            } catch (e) {
                console.error(e);
            }

        }                                      
    }
};
</script>